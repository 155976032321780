.side-menu-btn {
  background-color: initial;
  background-image: linear-gradient(
    hsl(var(--hue), calc(var(--sat) + 7%), calc(var(--lig) + 9%)),
    hsl(var(--hue), var(--sat), var(--lig)) 50%
  );
  box-shadow: hsla(
        var(--hue),
        calc(var(--sat) + 7%),
        calc(var(--lig) + 9%),
        0.24
      )
      0 1px 1px,
    hsla(var(--hue), var(--sat), var(--lig), 0.4) 0 4px 6px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 7px;
  cursor: pointer;
  color: black;
  font: 600 1rem/1 'Nunito', sans-serif;
  letter-spacing: 0.05em;
  overflow: hidden;
  padding: 0.65em 0.95em;
  min-height: 2.5em;
  position: relative;
  width: 100%;
}

.side-menu-btn--yellow {
  --hue: 46;
  --sat: 85%;
  --lig: 82%;
}
.side-menu-btn--green {
  --hue: 169;
  --sat: 38%;
  --lig: 61%;
}
.side-menu-btn--purple {
  --hue: 294;
  --sat: 20%;
  --lig: 81%;
}
.side-menu-btn--red {
  --hue: 352;
  --sat: 77%;
  --lig: 76%;
}
.side-menu-btn--blue {
  --hue: 210;
  --sat: 62%;
  --lig: 77%;
}
.side-menu-btn--orange {
  --hue: 25;
  --sat: 81%;
  --lig: 79%;
}
.side-menu-btn:hover,
.side-menu-btn:focus,
.side-menu-btn:active {
  outline: 3px solid hsl(calc(var(--hue) + 10), 98%, 80%);
}
