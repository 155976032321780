/* Slide Übergang */
.slide-enter {
  transform: translateX(200%);
}
.slide-enter-active {
  transform: translateX(0%);
  transition: transform 1s cubic-bezier(0.85, 0, 0.15, 1);
}
.slide-exit {
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-200%);
  transition: transform 1s cubic-bezier(0.85, 0, 0.15, 1);
}

.message-rotator {
  background: #1d976c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    var(--primary-color),
    #74c1b3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    var(--primary-color),
    #74c1b3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: #000000;
  line-height: 1.9rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  width: 100%;
  height: 1.8rem;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center !important;
  font-weight: 600;
  position: relative;
}

.message-rotator div {
  position: absolute; /* Verhindert Layout-Sprünge */
  width: 100%; /* Stellt sicher, dass der Text immer die gesamte Breite nutzt */
}
