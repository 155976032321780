.green-week-container {
  background-color: #f4f4f9;
}

.green-week-header {
  position: relative;
  height: 60vh;
  background-image: url('https://cdn.braunbaerchen.de/home/green-week-black-ween-black-friday-plant-a-tree-braunbaerchen-gruene-woche-baumpflanzen.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  overflow: hidden;
}

/* Header Overlay und Animation */
.header-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 12px;
  animation: slide-in 2s ease-in-out;
}

.green-week-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  opacity: 0;
  animation: fade-in 2.5s forwards;
}

/* Green Week Content Styling */
.green-week-content {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.green-week-text {
  background: none;
  padding: 1.5rem;
}

.green-week-text h2 {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 2rem;
  color: #2c6e49;
}

.green-week-text p {
  margin: 1rem 0;
  font-size: 1rem;
  color: #555;
}

.green-week-text strong {
  color: #2c6e49;
}

/* Kachel-Bereich */
.how-it-works {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}

.step {
  flex: 1;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
  position: relative;
  opacity: 0;
  transform: translateY(50px);
  animation: fade-in-step 2s ease-out forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 250px;
  min-width: 250px;
  box-sizing: border-box;
}

.step h3 {
  font-size: 1.2rem;
  color: #2c6e49;
  margin: 0;
}

.step p {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}

/* Pfeil-Styling */
.arrow {
  font-size: 2rem;
  color: #2c6e49;
  margin: 0;
  align-self: center;
}

/* Verzögerung für Kacheln */
.delay-1 {
  animation-delay: 0.6s;
}

.delay-2 {
  animation-delay: 1.2s;
}

.delay-3 {
  animation-delay: 1.8s;
}

/* Animation Keyframes */
@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fade-in-step {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Anpassungen */
@media (max-width: 991px) {
  .how-it-works {
    flex-direction: column;
    justify-content: center;
  }

  .how-it-works > div {
    max-width: 25rem;
  }

  .green-week-header h1 {
    font-size: 1.8rem;
  }

  .step {
    margin-bottom: 1rem;
  }
}

.green-week-footer-image {
  width: 100%;
  height: 150px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://cdn.braunbaerchen.de/home/erde-liebe-herz-green-week.webp');
}

.green-week-header-container {
  background: var(--navbar-bg-color);
  color: #000000;
  line-height: 1.9rem;
  font-size: 0.8rem;
  justify-content: center;
  position: relative;
}

.green-week-color {
  color: #2c6e49;
}
