.reservedSpan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(15deg);
  background-color: #f8eba8;
  color: #000000;
  padding: 0.5em 0.5em;
  border-radius: 15px;
  font-size: 0.75em;
  white-space: nowrap;
}

.ribbon {
  margin: 0;
  color: black;
  padding: 0.25em 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
  min-width: 50px;
  text-align: center;
  z-index: 3;
  font-size: 0.8rem;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top: 0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
}
.ribbon:before {
  right: 100%;
}

.ribbon:after {
  left: 100%;
}

.bg-red,
.bg-red:before,
.bg-red:after {
  background: #ffdfba;
}

.bg-blue,
.bg-blue:before,
.bg-blue:after {
  background: #bae1ff;
}

.bg-green,
.bg-green:before,
.bg-green:after {
  background: #baffc9;
}

.bg-orange,
.bg-orange:before,
.bg-orange:after {
  background: #ffffba;
}
