h1 {
  /* font-size: calc(1.3rem + 1.3vw); */
  font-size: 2.5rem;
}

.homescreen_carousel {
  overflow-x: hidden;
}

.homescreen_carousel > .carousel-inner {
  margin-bottom: 0;
}

.homescreen_carousel_item {
  /* max-height: 60vh; */
  min-height: 550px;
  width: 100%;
}

.homescreen_carousel_item_2 {
  background-image: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.3) 25%,
      rgba(15, 1, 1, 0.5) 75%
    ),
    url(https://cdn.braunbaerchen.de/home/children-piggyback-sun-grass.webp);
  background-size: cover;
  background-position: 50% 25%;
  height: 550px;
}

.homescreen_carousel_item_3 {
  background-image: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.3) 25%,
      rgba(24, 24, 24, 0.5) 75%
    ),
    url(https://cdn.braunbaerchen.de/home/herbst-sale-wintermode-rabatt.webp);
  background-size: cover;
  background-position: center center;
  height: 550px;
}
/* https://cdn.braunbaerchen.de/home/kids-playing-wood-toys.webp */

@media screen and (max-width: 600px) and (orientation: portrait) {
  .homescreen_carousel_item_2 {
    background-image: radial-gradient(
        circle,
        rgba(0, 0, 0, 0.3) 25%,
        rgba(24, 24, 24, 0.5) 75%
      ),
      url(https://cdn.braunbaerchen.de/home/children-piggyback-sun-grass-mobile.webp);
  }

  .homescreen_carousel_item_3 {
    background-image: radial-gradient(
        circle,
        rgba(0, 0, 0, 0.3) 25%,
        rgba(24, 24, 24, 0.5) 75%
      ),
      url(https://cdn.braunbaerchen.de/home/herbst-sale-wintermode-rabatt-mobile.webp);
  }

  .homescreen_carousel_caption_advent h1 {
    font-size: 2rem;
  }

  .homescreen_carousel_caption_advent div {
    font-size: 1.3rem;
  }

  .homescreen_carousel_caption_advent sub,
  .homescreen_carousel_caption_2 sub {
    font-size: 0.35em !important;
  }
}
/* https://cdn.braunbaerchen.de/home/kids-playing-wood-toys-mobile.webp */

.homescreen_carousel_caption {
  left: 5%;
  right: 5%;
  bottom: 5%;
  top: 5%;
}

.homescreen_carousel_caption_advent {
  text-align: right;
}

.homescreen_carousel_caption_1 {
  text-align: left;
}

.homescreen_carousel_caption_2 {
  text-align: center;
}

.homescreen_carousel_caption_3 {
  text-align: left;
}

.homescreen_carousel_caption > div {
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.07rem;
}

.homescreen_carousel_caption_advent > div {
  right: 0px;
  bottom: 0;
}

.homescreen_carousel_caption_advent sub,
.homescreen_carousel_caption_2 sub {
  font-size: 0.5em;
  margin-top: 2rem;
}

.homescreen_carousel_caption_1 > div {
  left: 0px;
  bottom: 0px;
}

.homescreen_carousel_caption_2 > div,
.homescreen_carousel_caption_advent_rabatt > div {
  bottom: 0px;
}

.homescreen_carousel_caption_3 > div {
  left: 0px;
  bottom: 0px;
}

.headline_highlights h1 {
  font-weight: 400;
}

.headline_highlights h1:before {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 2px;
  content: '';
  background-color: var(--primary-color);
  left: 50%;
  margin-left: -50px;
}

.marquee_highlights_container > .rfm-marquee {
  margin-bottom: 3rem;
}

.marquee_highlights {
  width: 15rem;
  /* height: 26rem; */
}

.homescreen_carousel_caption_green-week {
  text-align: left;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .homescreen_carousel_caption_green-week {
    text-align: center;
  }
}

.homescreen_carousel_caption_green-week h1 {
  margin-bottom: 0;
  font-weight: 600;
}

.homescreen_carousel_caption_green-week h1:first-child {
  font-size: min(12vw, 4.5rem);
  color: #61cb00;
  /* text-shadow: 0vw 0vw 1vw seagreen, 0vw 0vw 2vw limegreen; */
  /* text-shadow: -1px 0 5px rgb(53, 156, 53), 0 1px 5px rgb(53, 156, 53),
    1px 0 5px rgb(53, 156, 53) limegreen, 0 -1px 5px rgb(53, 156, 53); */
  text-shadow: -1px 0 rgba(0, 0, 0, 0.164), 0 1px rgba(0, 0, 0, 0.164),
    1px 0 rgba(0, 0, 0, 0.164), 0 -1px rgba(0, 0, 0, 0.164);
  margin-top: 2rem;
  line-height: min(12vw, 4rem);
}

.homescreen_carousel_caption_green-week h2 {
  font-size: min(5vw, 2rem);
  color: black;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 2rem;
}

.homescreen_carousel_caption_green-week h1:last-child {
  font-size: min(10vw, 4rem);
  color: black;
  line-height: min(10vw, 4rem);
}
