.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.block-scroll {
  overflow: hidden !important;
  touch-action: none;
  -ms-touch-action: none;
}

.productcard {
  background-color: var(--content-bg-color);
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(17, 17, 26, 0.075) 0px 6px 12px;
  margin: 0.5rem !important;
}

.product img {
  width: 90%;
  height: auto;
  transition: opacity 1s;
}

.carousel-indicators {
  margin-bottom: 0;
}

.carousel-inner {
  margin-bottom: 2rem;
}

.product__image {
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.product__image_incart {
  opacity: 0.3;
}

.card-body {
  background-color: var(--content-bg-color);
  padding-top: 0;
  justify-content: end;
}
.card-body a {
  text-decoration: none;
}

.card-title {
  font-weight: 500;
  font-size: 1.1rem;
  color: #000;
  margin-bottom: 0.15rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
}

.productSize {
  font-weight: 500;
  font-size: 0.75rem;
}

.productPrice {
  font-weight: 500;
  font-size: 1rem;
  color: #339281;
  /* margin-top: 0.5rem; */
}

.price-tag {
  background-image: linear-gradient(
    var(--price-tag-background-2),
    var(--price-tag-background) 50%
  );
  color: #fff;

  /* Center the price */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Used to position the triangle */
  position: relative;

  /* Size */
  height: var(--price-tag-height);

  /* Spacing */
  padding: 0.25rem 0.5rem 0.25rem calc(0.5rem + var(--price-tag-height) / 2);
  clip-path: polygon(
    calc(var(--price-tag-height) / 2) 0,
    100% 0,
    100% 100%,
    calc(var(--price-tag-height) / 2) 100%,
    0% 50%
  );
}

.price-tag-sale {
  background-image: linear-gradient(
    var(--sale-tag-background-2),
    var(--sale-tag-background) 50%
  );
}

/* The dot */
.price-tag::after {
  content: '';

  /* Make it like a cirle */
  background: #fff;
  border-radius: 9999rem;

  /* Position */
  left: calc(var(--price-tag-height) / 2);
  position: absolute;
  top: 50%;
  transform: translate(-0.15rem, -50%);

  /* Size */
  height: 0.3rem;
  width: 0.3rem;

  box-shadow: -1px -1px 2px #004977;
}

.sale-tag {
  /* background-image: linear-gradient(
    var(--sale-tag-background-2),
    var(--sale-tag-background) 50%
  ); */
  color: #ff1f1f;
  background-color: white;

  border: 1px solid #ff1f1f;

  font-weight: 600;

  /* Center the price */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Used to position the triangle */
  position: relative;

  /* Size */
  height: var(--price-tag-height);

  /* Spacing */

  /* clip-path: polygon(
    0 0,
    calc(100% - calc(var(--price-tag-height) / 2)) 0,
    100% 50%,
    calc(100% - calc(var(--price-tag-height) / 2)) 100%,
    0% 100%
  ); */
}

.price-col {
  white-space: nowrap;
  font-weight: 600;
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.3s;
}

.no-mw {
  min-width: unset !important;
}

.img-thumbnail {
  width: 5em;
  border: 0;
  background-color: transparent;
}

.small-container {
  max-width: 37.5em;
}

input[type='radio'] {
  border-color: #00000083;
}

.formgrp_select {
  border: 1px solid #33333377;
  border-radius: 0.5rem;
  background-color: #e2e2e217;
}

.formgrp_select[disabled] {
  color: gray;
}

.formgrp_select_selected {
  background-color: #66ff6e38;
}

.formgrp_select:hover:not([disabled]) {
  border: 1px solid #333333;
  border-radius: 0.5rem;
  cursor: pointer;
}

.checkout {
  font-size: 0.9rem;
}

.checkout-header h1 {
  font-size: 1.25rem;
  font-weight: 600;
}

.checkout h2 {
  font-size: 0.95rem;
  font-weight: 600;
  /* word-break: break-all; */
  hyphens: auto;
}

.checkout h3 {
  font-size: 0.9rem;
  font-weight: 600;
  /* word-break: break-all; */
  hyphens: auto;
}

footer {
  position: relative;
  /* Set the fixed height of the footer here */
  min-height: 3em;
  border-top: 3px transparent;
  background-color: var(--navbar-bg-color);
  color: #4f2b12;
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-radius: 2px;
  z-index: 0;
}
footer li {
  display: inline;
  list-style: none;
}

footer li:after {
  content: ' \00b7   ';
  font-weight: bold;
}
footer li:last-child:after {
  content: none;
}

footer a {
  text-decoration: none;
  color: #4f2b12;
  display: flexbox;
}

.link {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}

.side-menu {
  position: fixed;
  margin-top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 9;
  max-width: inherit;
}

.side-menu-left {
  margin-left: 0;
  margin-right: 0;
}

.side-menu__content {
  box-sizing: border-box;
  position: relative;
  top: 0px;
  padding: 10px 20px;
  z-index: 8;
  height: 100%;
  width: 100%;
  max-width: 30rem;
  background-color: var(--navbar-bg-color);
  box-shadow: 0 0 2rem rgba(206, 252, 255, 0.1);
  transition: transform 350ms linear;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.side-menu-left__content {
  float: left;
  transform: translateX(-103%);
}

.side-menu-right__content {
  float: right;
  transform: translateX(203%);
}

.side-menu__content::-webkit-scrollbar {
  display: none;
}

.side-menu__content--active {
  transform: none;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.side-menu-left__content--active {
  border-right: 1px solid var(--border-color);
}

.side-menu-right__content--active {
  border-left: 1px solid var(--border-color);
  right: 0px;
}

.side-menu__overlay {
  position: absolute;
  display: block;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: #424246;
  opacity: 0;
  transition: opacity 300ms linear;
}

.side-menu-left__overlay {
  left: 0;
}

.side-menu-right__overlay {
  right: 2px;
}

.side-menu--active {
  pointer-events: auto;
}

.side-menu--active .side-menu__overlay {
  opacity: 0.3;
}

.side-menu h2 {
  margin-top: 1rem;
  font: 600 0.85rem/1 'Nunito', sans-serif;
  letter-spacing: 0.05em;
  padding-top: 15px;
}

.side-menu h2 a {
  text-decoration: none;
  color: #333;
}
.side-menu h2 a:hover {
  text-decoration: underline;
  scale: 1.2;
}

.side-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-menu ul li {
  margin-bottom: 10px;
}

.filter-checkbox-input {
  border: 2px solid #3333332f;
  border-radius: 0.2em;
  background-color: #82cfc1ad; /* #d6ffffad; */
}
.filter-checkbox-input:focus {
  outline: none;
}

.filter-checkbox-list {
  overflow-y: scroll;
  height: 15em;
  border: 2px solid #3333332f;
  border-radius: 0.25em;
  background-color: #82cfc1ad;
}
.filter-checkbox-list__upper-category {
  background-color: #00686866;
}

.filter-checkbox-list__input {
  font-size: 1.1rem;
  padding-block: 0.1rem;
  cursor: pointer;
}

.filter-checkbox-list__input > input,
.filter-checkbox-list__input > label {
  cursor: pointer;
}

.filter-checkbox-list__input > label {
  width: 100%;
}

.filter-checkbox-list__input > input:checked {
  background-color: #113630b4;
  border-color: #113630b4;
}
.filter-checkbox-list__input > input:hover,
.filter-checkbox-list__input > input:focus {
  box-shadow: 0px 0px 4px #006868d3;
}

.btn-check:checked + .btn-filterSize {
  background-color: #137c69;
  color: #fff;
}
.btn-filterSize {
  margin: 0.3em !important;
  min-width: 4em;
  min-height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto !important;
  background-color: var(--primary-color);
  border: 1px solid rgba(0, 0, 0, 0.26);
  cursor: pointer;
}

.btn-filterSize:hover {
  background-color: #74c1b3;
}

.closeSideMenu {
  position: absolute;
  right: 1.25rem;
  font-size: 1.75rem;
  cursor: pointer;
}

.closeSideMenuRight {
  position: absolute;
  font-size: 1.75rem;
  cursor: pointer;
}

.coming-soon span {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: #e01717;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.75em;
}

.coming-soon {
  position: absolute;
  top: 1.2em;
  right: 0.35em;
  background-color: #e01717;
  color: #fff;
  padding: 0.5em 0.5em;
  border-radius: 15px;
  font-size: 0.6em;
  transform: rotate(15deg);
}

.info h1 {
  font-size: 2rem;
}

@media screen and (max-width: 400px) {
  .info h1 {
    font-size: 1.5rem;
  }
}

.info h2 {
  font-size: 1.2em;
  font-weight: 600;
}

.info p {
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
}

button.thumbnail {
  padding: 0;
}

.btn-deleteImg {
  position: absolute;
  left: 50%;
}

.bg_transp {
  background: transparent;
  --bs-table-bg: transparent;
}

.cartList__item {
  transition: 2s opacity;
  cursor: pointer;
}

.select-product-chbox > input {
  width: 1.5rem;
  height: 1.5rem;
}

.cart_header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.225) !important;
  background-color: var(--navbar-bg-color);
}

.cart_footer {
  border-top: 2px solid rgba(0, 0, 0, 0.225) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.225) !important;
}

.btn_light {
  color: #181818;
  width: 75%;
  border: transparent;
  background-color: transparent;
  max-width: 20rem;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}

.btn_light:active {
  color: #000 !important;
  background-image: linear-gradient(#f8f8f8, #eeeeee 50%);
}

.text_cart__size {
  font-size: 0.8em;
}

.filter_header {
  font-size: 0.9em;
  font-weight: 500;
  margin-left: 0.75em;
  cursor: pointer;
}
.filter_header_category {
  margin-left: 0.1em;
}

.cart_total__footer {
  margin-top: 0.25em;
  font-size: 0.6em;
  color: rgba(0, 0, 0, 0.5);
}

.btn_goBack {
  color: #363638;
  width: 2.5em;
  height: 2.5em;
  border-radius: 1.25em;
  text-align: center;
}

.footerlist div {
  padding: 0.15em !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: fit-content;
}

.list-group {
  --bs-list-group-bg: transparent;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.225);
  border-top: 1px solid rgba(0, 0, 0, 0.225);
  border-bottom: 1px solid rgba(0, 0, 0, 0.225);
}

.btn-discount,
.btn-discount:focus {
  border-color: var(--bs-border-color);
  background-color: var(--primary-color);
  color: #181818;
}

.btn-discount:hover,
.btn-discount:active {
  background-color: var(--secondary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(165, 213, 204, 0.6) !important;
}

.btn-discount:active {
  box-shadow: inset 0 0 5px rgba(49, 92, 84, 0.75) !important;
}

.discount-input:focus {
  border-color: var(--primary-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(165, 213, 204, 0.6);
}
