/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/poppins-v21-latin-regular.woff2')
      format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('../src/assets/fonts/poppins-v21-latin-regular.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../src/assets/fonts/poppins-v21-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('../src/assets/fonts/poppins-v21-latin-500.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../src/assets/fonts/poppins-v21-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('../src/assets/fonts/poppins-v21-latin-600.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* poppins-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../src/assets/fonts/poppins-v21-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('../src/assets/fonts/poppins-v21-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* archivo-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/archivo-v19-latin-regular.woff2')
      format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('../src/assets/fonts/archivo-v19-latin-regular.ttf')
      format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* archivo-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  src: url('../src/assets/fonts/archivo-v19-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('../src/assets/fonts/archivo-v19-latin-500.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* archivo-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  src: url('../src/assets/fonts/archivo-v19-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('../src/assets/fonts/archivo-v19-latin-600.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* archivo-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  src: url('../src/assets/fonts/archivo-v19-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('../src/assets/fonts/archivo-v19-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* eater-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Eater';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/eater-v25-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
      url('../src/assets/fonts/eater-v25-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

:root {
  --main-bg-color: #fff;
  --navbar-bg-color: #f2ffff;
  --border-color: rgba(0, 102, 105, 0.15);
  --content-bg-color: #fffffa;
  --content-font-color: #5a3a2d;
  --primary-color: #a5d5cc;
  --secondary-color: #74c1b3;

  --price-tag-background: #339281;
  --price-tag-background-2: #57c9b4;
  --price-tag-height: 2rem;

  --sale-tag-background: #ff4f4f;
  --sale-tag-background-2: #e10000;
}
@media screen and (max-width: 400px) {
  :root {
    --main-bg-color: #fffffa;
  }
}

html {
  scroll-behavior: auto !important;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: auto !important;
  display: flex;
  justify-content: center;
}

main {
  position: relative;
  min-height: 100vh;
  background-color: var(--content-bg-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  max-width: inherit;
}
header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 5;
}

body {
  margin: auto;
  overflow-x: hidden;
  font-family: 'Poppins', 'Helvetica', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  /* font-family: 'Archivo', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: 'wdth' 100; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 0); /* var(--main-bg-color); */
  overscroll-behavior: none;
  width: 100%;
}

strong {
  font-weight: 600;
}

.btn_action {
  color: #181818;
  width: fit-content;
  min-width: 14rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
  border-radius: 0;
  max-width: 20rem;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem !important;
  letter-spacing: 0;
  padding: 0.8rem 1.6rem;
}

.btn_action_disabled,
.btn_action:disabled {
  color: #181818;
  width: fit-content;
  min-width: 14rem;
  border: 1px solid #a5d5cc;
  background-color: transparent;
  cursor: none;
  pointer-events: none;
}

.btn_action:hover {
  background-color: var(--secondary-color);
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #181818;
}

.btn_action:active {
  background-color: var(--secondary-color) !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #181818 !important;
}

.btn_klarna {
  background-color: #ffb3c7;
  line-height: 2rem;
  align-items: end;
  word-wrap: break-word;
}

.btn_klarna:hover,
.btn_klarna:active {
  background-color: #ffb3c7 !important;
}

@media (hover: none) {
  .btn_action:hover {
    background-color: var(--primary-color);
  }
  .btn_action_disabled:hover {
    background-color: transparent;
  }
}

.navbar {
  border-bottom: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-radius: 1px;
  margin: 0;
  background-color: var(--navbar-bg-color);
  position: absolute;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
}

.topbar {
  background: #1d976c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    var(--primary-color),
    #74c1b3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    var(--primary-color),
    #74c1b3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: #000000;
  line-height: 1.8rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.topbar div {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600;
}

.menuButton {
  background-color: transparent;
  border: rgb(79, 43, 18, 0.3);
  border-style: solid;
  border-width: 1px;
}

.navbar_category {
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 400;
  text-align: center;
  font-size: 0.85rem;
  padding-bottom: 0.25rem;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: #4f2b12;
}

.navbar_category:hover {
  opacity: 1;
}

.navbar_category::before {
  width: 100%;
  bottom: -2px;
  opacity: 0;
  transition: 300ms;
  height: 3px;
  content: '';
  position: absolute;
  background-color: #4f2b12;
}

.navbar_category:hover::before {
  bottom: 0px;
  opacity: 1;
}

.adminDropdown {
  position: absolute;
  top: 2em;
  right: 4em;
}
.adminDropdown a {
  color: #4f2b12;
}

.cart-badge {
  font-size: 0.7em;
  position: relative;
  top: -0.8em;
  left: 3.8em;
  z-index: 6;
  transition: opacity 0.25s;
}

.cart-badge-hidden {
  visibility: hidden;
}

.wrapper {
  position: relative;
  max-width: inherit;
  overflow-x: hidden;
  padding-top: 1rem;
}

.update-notification {
  position: sticky;
  top: 0;
  width: 100%;
  height: 4rem;
  background-color: var(--content-bg-color);
  color: black;
  text-align: center;
  padding: 10px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
}

.update-notification p {
  margin: 0;
  padding: 0;
}

.update-notification button {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 0.8rem;
  min-width: 0;
}

.update-notification .close-btn {
  background: transparent;
  color: black;
  border: none;
  cursor: pointer;
}

.condition-modal-backdrop {
  z-index: 1055;
}

.homescreen-content > .row:nth-child(2n) {
  background-color: rgb(242 255 255);
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-size: calc(0.35em + 0.375rem) calc(0.35em + 0.375rem);
  padding-right: 0.75rem;
}
