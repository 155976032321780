.review-card {
  min-width: 20rem;
  max-width: 50rem;
  width: 80vw;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.225) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.1) 0px 2px 6px 2px;
  transition: transform 0.3s;
}

.review-card:hover {
  transform: translateY(-10px);
}

.review-card-title {
  font-weight: bold;
  color: #333;
}

.review-card-time {
  font-size: 0.85rem;
}

.review-card-subtitle {
  font-weight: 500;
  color: #6c757d;
}

.review-card-text {
  color: #555;
  font-size: 0.9rem;
}

.review-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.review-carousel-item {
  padding: 20px;
}

.review-reply {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  border-left: 3px solid #007bff;
}

.star {
  color: #ffd700;
  margin-right: 5px;
}

.google-icon {
  color: #db4437;
  font-size: 24px;
}
